import { NextSeo } from 'next-seo';

/**
 * Custom SEO component
 *
 * Used to seo meta tags for each page
 *
 * @param {Object} seo Seo.
 * @param {boolean} preview is Preview or not.
 * @see https://www.npmjs.com/package/next-seo
 *
 * @returns {JSX.Element}
 *
 */

const Seo = ( { seo, preview } ) => {
  if (seo == undefined) { return null; }

	const {
    title,
    metaDesc,
    opengraphSiteName,
    opengraphTitle,
    opengraphDescription,
    opengraphUrl,
    opengraphImage
	} = seo;

	return (
		<NextSeo
			title={title}
			description={metaDesc || opengraphDescription}
			canonical={opengraphUrl?.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, process.env.NEXT_PUBLIC_NEXTJS_PUBLIC_URL)}
			noindex={(process.env.ENVIRONNEMENT == 'prod' && preview != true) ? false : true}
			nofollow={(process.env.ENVIRONNEMENT  == 'prod' && preview != true) ? false : true}
			openGraph={{
				type: 'website',
				locale: 'fr_FR',
				url: opengraphUrl?.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, process.env.NEXT_PUBLIC_NEXTJS_PUBLIC_URL),
				title: opengraphTitle,
				description: opengraphDescription,
				images: [
					{
						url: opengraphImage?.sourceUrl,
						width: 1280,
						height: 720
					}
				],
				site_name: opengraphSiteName
			}}
		/>
	);
};

export default Seo;