import { useEffect } from "react";
//context
import { useGlobalContext } from "../contexts";
//apollo
import client from "../src/utils/apollo/client"
//queries
import { GET_HOMEPAGE } from "../src/queries/pages/get-homepage";
//routing
import {handleRedirectsAndReturnData} from '../src/utils/slug';
// Components
import Layout from "../src/components/layout"

export default function Home( {data} ) {
  const { context, setContext } = useGlobalContext();

  useEffect(() => {
    if (!context.isSet) {
      data.context.options.isSet = true;
      setContext(data.context.options);
    }
  }, []);

  return (
    <Layout
      logos={data?.logos?.options?.logos}
      mainMenu={data?.mainMenu?.edges}
      seo={data?.content?.seo}
      breadcrumbs={data?.content?.seo?.breadcrumbs}
      content={data?.content}
      pt_key={process.env.HP_PT_KEY}
      templateWhite={data?.content?.acfSidebar?.bgWhite}
      optionsFooter={data?.content?.acfSidebar?.optionsFooter}
      testimonies={data?.testimonies?.nodes}
      programms={data?.programms}
      partners={data?.partners?.options?.partners}
      lastnews={data?.lastnews?.nodes}
      isHome={true}
    ></Layout>
  )
}

export async function getStaticProps() {

  const { data: data, errors: errors } = await client.query({
    query: GET_HOMEPAGE,
    variables: {
      programmsCategoryId: parseInt(process.env.PRODUCT_PROGRAMM_CATEGORY_ID)
    }
  });

  const defaultProps = {
		props: {
			data: data || {}
		},
		revalidate: 1,
	};

  return handleRedirectsAndReturnData( defaultProps, data, 'page' );
};