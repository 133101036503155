import React, { useEffect, useState } from "react";

import Footer from "../footer";

const Block = ({blockData, breadcrumbs, templateWhite, pt_key, category, armchairTagLine, productFeaturedImage, programmInfo, tags, posts, postFeaturedImage, programms, partners, testimonies, lastnews, armchairs, accessories, teamMembers, commercialAgents, testimoniesForListing, showrooms, comparatorDatas, postTags, systemTagLine, titleFont, systems, logos, optionsFooter}) => {
  const importComponent = async (componentName) => {
    const componentMap = {
      [pt_key + '_BlockHpIntroduction']: () => import("../blocks/BlockHpIntroduction"),
      [pt_key + '_BlockSliderTechnology']: () => import("../blocks/BlockHpSliderTechnologyv2"),
      [pt_key + '_BlockHpWhybl']: () => import("../blocks/BlockHpWhybl"),
      [pt_key + '_BlockForWho']: () => import("../blocks/BlockHpForWho"),
      [pt_key + '_BlockBrainlightSystems']: () => import("../blocks/BlockHpBlSystems"),
      [pt_key + '_BlockRelaxProgramms']: () => import("../blocks/BlockHpRelaxProgramms"),
      [pt_key + '_BlockHpBanner']: () => import("../blocks/BlockHpBanner"),
      [pt_key + '_BlockPartners']: () => import("../blocks/BlockPartners"),
      [pt_key + '_BlockCompanyBanner']: () => import("../blocks/BlockHpCompanyBanner"),
      [pt_key + '_BlockHpNews']: () => import("../blocks/BlockHpNews"),
      [pt_key + '_BlockHeader']: () => import("../blocks/BlockCommonHeader"),
      [pt_key + '_BlockImageText']: () => import("../blocks/BlockCommonImgTxt"),
      [pt_key + '_BlockPageBanner']: () => import("../blocks/BlockPageBanner"),
      [pt_key + '_BlockPageTeam']: () => import("../blocks/BlockPageTeam"),
      [pt_key + '_BlockPartners']: () => import("../blocks/BlockPartners"),
      [pt_key + '_BlockPageAwards']: () => import("../blocks/BlockPageAwards"),
      [pt_key + '_BlockRewardsDetails']: () => import("../blocks/BlockRewardsDetails"),
      [pt_key + '_BlockMapReseller']: () => import("../blocks/BlockPageMapReseller"),
      [pt_key + '_BlockPageNews']: () => import("../blocks/BlockHpNews"),
      [pt_key + '_BlockPageBannerBsb']: () => import("../blocks/BlockPageBannerBsb"),
      [pt_key + '_BlockPageTeamBig']: () => import("../blocks/BlockPageTeamBig"),
      [pt_key + '_BlockPageSliderCommercialAgents']: () => import("../blocks/BlockPageSliderCommercialAgents"),
      [pt_key + '_BlockListingTestimonies']: () => import("../blocks/BlockListingTestimonies"),
      [pt_key + '_BlockForm']: () => import("../blocks/BlockPageForm"),
      [pt_key + '_BlockBlExperience']: () => import("../blocks/BlockBlExperience"),
      [pt_key + '_BlockProductsMea']: () => import("../blocks/BlockProductsMea"),
      [pt_key + '_BlockArmchairsMea']: () => import("../blocks/BlockArmchairsmea"),
      [pt_key + '_BlockArchiveRelaxProgramms']: () => import("../blocks/BlockArchiveRelaxProgramms"),
      [pt_key + '_BlockBannerAccessories']: () => import("../blocks/BlockBannerAccessories"),
      [pt_key + '_BlockListingArmchairs']: () => import("../blocks/BlockListingArmchairs"),
      [pt_key + '_BlockProductSubmenu']: () => import("../blocks/BlockSubmenu"),
      [pt_key + '_BlockHeaderArmchair']: () => import("../blocks/BlockHeaderArmchair"),
      [pt_key + '_BlockContenu']: () => import("../blocks/BlockContent"),
      [pt_key + '_BlockImageGallery']: () => import("../blocks/BlockImageGallery"),
      [pt_key + '_BlockTitleText']: () => import("../blocks/BlockTitleText"),
      [pt_key + '_BlockColorChoice']: () => import("../blocks/BlockColorChoice"),
      [pt_key + '_BlockVideo']: () => import("../blocks/BlockVideo"),
      [pt_key + '_BlockTechnologies']: () => import("../blocks/BlockTechnologies"),
      [pt_key + '_BlockReassurance']: () => import("../blocks/BlockReassurance"),
      [pt_key + '_BlockCtaTest']: () => import("../blocks/BlockCtaTest"),
      [pt_key + '_BlockOtherProgramms']: () => import("../blocks/BlockOthersProgramms"),
      [pt_key + '_BlockOthersArmchairs']: () => import("../blocks/BlockOthersArmchairs"),
      [pt_key + '_BlockHeaderProgramm']: () => import("../blocks/BlockHeaderProgramm"),
      [pt_key + '_BlockProgrammPresentation']: () => import("../blocks/BlockProgrammPresentation"),
      [pt_key + '_BlockBannerWTitle']: () => import("../blocks/BlockBannerWTittle"),
      [pt_key + '_BlockInfiniteCard']: () => import("../blocks/BlockInfiniteCards"),
      [pt_key + '_BlockCompare']: () => import("../blocks/BlockComparator"),
      [pt_key + '_BlockHeaderBlog']: () => import("../blocks/BlockHeaderBlog"),
      [pt_key + '_BlockListingPosts']: () => import("../blocks/BlockListingPosts"),
      [pt_key + '_BlockWysiwyg']: () => import("../blocks/BlockWysiwyg"),
      [pt_key + '_BlockPostBanner']: () => import("../blocks/BlockPostBanner"),
      [pt_key + '_BlockPostIntroduction']: () => import("../blocks/BlockPostIntroduction"),
      [pt_key + '_BlockPostContentImgRight']: () => import("../blocks/BlockPostContentImgRight"),
      [pt_key + '_BlockPostContentImgLeft']: () => import("../blocks/BlockPostContentImgLeft"),
      [pt_key + '_BlockHeaderAccessories']: () => import("../blocks/BlockAccessoriesHeader"),
      [pt_key + '_BlockOtherAccessories']: () => import("../blocks/BlockOtherAccessories"),
      [pt_key + '_BlockListingAccessories']: () => import("../blocks/BlockLisitingAccessories"),
      [pt_key + '_BlockMap']: () => import("../blocks/BlockMap"),
      [pt_key + '_BlockBuyButtons']: () => import("../blocks/BlockBuyButtons")
    }

    return componentMap[componentName] ? componentMap[componentName]() : null;
  }


  function BlockWrapper({ componentName, ...props}) {
    const [Component, setComponent] = useState(null);

    useEffect(() => {
      importComponent(componentName).then((mod) => {
        if (mod) setComponent(() => mod.default);
      });
    }, [componentName])

    if (!Component) return null;

    // add index for block order
    props.blockInfo.index = props.index;
    // add template informations
    props.blockInfo.templateWhite = templateWhite;
    // add breadcrumbs if needed
    if ( componentName == pt_key + '_BlockHeader'
      || componentName == pt_key + '_BlockHeaderArmchair'
      || componentName == pt_key + '_BlockHeaderProgramm'
      || componentName == pt_key + '_BlockHeaderBlog'
      || componentName == pt_key + '_BlockHeaderAccessories') {
      props.blockInfo.breadcrumbs = breadcrumbs;
    }
    // add featuredImage if needed
    if ( componentName == pt_key + '_BlockHeaderArmchair'
      || componentName == pt_key + '_BlockHeaderProgramm'
      || componentName == pt_key + '_BlockHeaderAccessories') {
      props.blockInfo.productFeaturedImage = productFeaturedImage;
    }
    // add armchair tagline in needed
    if ( componentName == pt_key + '_BlockHeaderArmchair') {
      props.blockInfo.armchairTagLine = armchairTagLine;
    }
    // add system tagline in needed
    if ( componentName == pt_key + '_BlockHeaderAccessories') {
      props.blockInfo.systemTagLine = systemTagLine;
    }
    // add category id needed
    if ( componentName == pt_key + '_BlockProductSubmenu' ) {
      props.blockInfo.category = category;
    }
    // add programmInfo if needed
    if ( componentName == pt_key + '_BlockHeaderProgramm') {
      props.blockInfo.programmInfo = programmInfo;
    }
    // add tags & posts if needed
    if (componentName == pt_key + '_BlockListingPosts') {
      props.blockInfo.tags = tags;
      props.blockInfo.posts = posts;
    }
    // add tags & featured image if needed
    if (componentName == pt_key + '_BlockPostIntroduction') {
      props.blockInfo.postFeaturedImage = postFeaturedImage;
      props.blockInfo.postTags = postTags;
    }
    // Add programms if needed
    if (componentName == pt_key + '_BlockRelaxProgramms'
       || componentName == pt_key + '_BlockArchiveRelaxProgramms'
       || componentName == pt_key + '_BlockProductSubmenu') {
      props.blockInfo.programms = programms;
    }
    // Add systems if needed
    if (componentName == pt_key + '_BlockProductSubmenu'
      || componentName == pt_key + '_BlockForm') {
      props.blockInfo.systems = systems;
    }
    // Add partners & testimonies if needed
    if (componentName == pt_key + '_BlockPartners') {
      props.blockInfo.partners = partners;
      props.blockInfo.testimonies = testimonies;
    }
    // Add lastnews if needed
    if (componentName == pt_key + '_BlockHpNews'
      || componentName == pt_key + '_BlockProductSubmenu'
      || componentName == pt_key + '_BlockPageNews') {
      props.blockInfo.lastnews = lastnews;
    }
    // Add armchairs if needed
    if (componentName == pt_key + '_BlockListingArmchairs'
      || componentName == pt_key + '_BlockProductSubmenu') {
      props.blockInfo.armchairs = armchairs;
    }
    // Add accessories if needed
    if (componentName == pt_key + '_BlockListingAccessories'
      || componentName == pt_key + '_BlockProductSubmenu') {
      props.blockInfo.accessories = accessories;
    }
    // Add team members if needed
    if (componentName == pt_key + '_BlockPageTeam'
      || componentName == pt_key + '_BlockPageTeamBig') {
      props.blockInfo.teamMembers = teamMembers;
    }
    // Add commercial agents if needed
    if (componentName == pt_key + '_BlockPageSliderCommercialAgents') {
      props.blockInfo.commercialAgents = commercialAgents;
    }
    // Add testimonies for listing if needed
    if (componentName == pt_key + '_BlockListingTestimonies') {
      props.blockInfo.testimoniesForListing = testimoniesForListing;
    }
    // Add showrooms for listing if needed
    if (componentName == pt_key + '_BlockMap'
      || componentName == pt_key + '_BlockForm') {
      props.blockInfo.showrooms = showrooms;
    }
    // Add comaprator datas if needed
    if (componentName == pt_key + '_BlockCompare') {
      props.blockInfo.comparatorDatas = comparatorDatas;
    }
    // add fontTitle if need
    if (componentName == pt_key + '_BlockHeaderArmchair') {
      props.blockInfo.titleFont = titleFont;
    }

    if (props.index + 1 != blockData.length) {
      return <Component {...props} />;
    } else {
      return (
        <>
          <Component {...props} />
          <Footer logos={logos} templateWhite={templateWhite} optionsFooter={optionsFooter}/>
        </>
      )
    }
  }

  return (
    <div className="blocks relative z-10">
      {blockData.map( (item, index) => (
        <BlockWrapper
          key={`${item.fieldGroupName}-${index}`}
          index={index}
          componentName={item.fieldGroupName}
          blockInfo={item}
          breadcrumbs={breadcrumbs}
          templateWhite={templateWhite}
          productFeaturedImage={productFeaturedImage}
        />
      ))}
    </div>
  )
}

export default Block;