import parse from 'html-react-parser';

import { LayoutContextProvider } from "../../../contexts/LayoutContext";

import { useRef } from "react";
import { useRouter } from "next/router";
import Head from "next/head"

import Seo from "../seo"
import Header from "../header"
import Block from "./blocks"
import CookiesComponent from "../commons/rgpd/CookiesComponent";

const Layout = ({logos, mainMenu, seo, breadcrumbs, content, pt_key, templateWhite, armchairTagLine, children, optionsFooter, productFeaturedImage, programms, partners, testimonies, lastnews, armchairs, accessories, programmInfo, tags, posts, postFeaturedImage, postTags, teamMembers, commercialAgents, testimoniesForListing, showrooms, comparatorDatas, systemTagLine, systems, isHome = false, preview = false}) => {
  const router = useRouter();
  const bgWMenuOpen = useRef(null);

  let blocks = content?.acfVars?.blocks;
  if (blocks == undefined) { blocks = false }

  return (
    <LayoutContextProvider>
      <Seo seo={seo} uri={router.asPath} preview={preview} />
      <Head>
        {logos?.favicon == null ? <link rel="shortcut icon" href="/images/favicon.ico" /> : <link rel="shortcut icon" href={logos?.favicon?.sourceUrl} />}
        {seo?.schemaDetails ? (
					<script
						type='application/ld+json'
						className='yoast-schema-graph'
						key='yoastSchema'
          >{parse(seo?.schemaDetails)}</script>
				) : null}
      </Head>
      <div className={`
        base-layout antialiased w-full h-full overflow-hidden
        ${content?.bodyClasses}
        ${templateWhite == true ? 'bg-white' : 'bg-primary'}
      `}>
        <div className={`${templateWhite == true ? 'transparent' : 'lg:bg-radial-gradient'}`}>
          <div ref={bgWMenuOpen} className={`bg-w-menu-open w-full h-full fixed top-0 left-0 z-40 opacity-0 transition-all bg-primary/70`} />
          <Header logos={logos} mainMenu={mainMenu} templateWhite={templateWhite} isHome={isHome} bgWMenuOpen={bgWMenuOpen} />
          {blocks != false  ? (
            <Block
              blockData={blocks}
              breadcrumbs={breadcrumbs}
              templateWhite={templateWhite}
              pt_key={pt_key}
              category={content?.categories?.nodes[0]}
              productFeaturedImage={productFeaturedImage}
              programms={programms}
              partners={partners}
              testimonies={testimonies}
              lastnews={lastnews}
              armchairs={armchairs}
              accessories={accessories}
              teamMembers={teamMembers}
              commercialAgents={commercialAgents}
              testimoniesForListing={testimoniesForListing}
              showrooms={showrooms}
              comparatorDatas={comparatorDatas}
              programmInfo={programmInfo}
              tags={tags?.nodes}
              posts={posts}
              postFeaturedImage={postFeaturedImage}
              postTags={postTags}
              armchairTagLine={armchairTagLine}
              systems={systems}
              systemTagLine={systemTagLine}
              titleFont={content?.acfArmchairs?.titleFont}
              logos={logos}
              optionsFooter={optionsFooter}
            />
            ) : null}
          {children}
        </div>

        <CookiesComponent />
      </div>
    </LayoutContextProvider>
  )
}

export default Layout